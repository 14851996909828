import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import locationHOC from '../locationProvider/locationHOC';
import { getQueryParam } from '../../lib/url';
import { QUERY_PARAMS } from '../../constants/navigation';
import { Button, notification } from 'antd';
import {t, Trans} from '@lingui/macro';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../constants/Rights';
import Editor from '../general/Editor';

/**
 * @dusan
 */

class CompanyDocument extends React.PureComponent {
    static propTypes = {
        loadFcn: PropTypes.func.isRequired,
        saveFcn: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            html: null,
            isEdit: false,
        }
    }

    componentDidMount() {
        this.loadDoc();
    }

    componentDidUpdate(prevProps) {
        const {location} = this.props;
        const {location: prevLocation} = prevProps;
        const lang = getQueryParam(location, QUERY_PARAMS.LANG);
        const prevLang = getQueryParam(prevLocation, QUERY_PARAMS.LANG);
        if(lang != prevLang)
            this.loadDoc();
    }

    startEdit = () => {
        this.setState({isEdit: true});
    };

    cancelEdit = () => {
        this.setState({isEdit: false});
        this.loadDoc();
    };

    onChange = (newHtml) => {
        this.setState({html: newHtml});
    };

    loadDoc = () => {
        const {location, loadFcn, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const lang = getQueryParam(location, QUERY_PARAMS.LANG);
        if(loadFcn == null)
        {
            this.setState({html: null, isEdit: false});
            return;
        }

        fetchHandler(
            loadFcn,
            {lang: lang},
            (res) => {
                this.setState({html: res, isEdit: false});
            }
        );
    }

    saveDoc = (newHtml) => {
        const {location, saveFcn, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const lang = getQueryParam(location, QUERY_PARAMS.LANG);

        if(saveFcn == null)
            return;

        fetchHandler(
            saveFcn,
            {lang: lang, html: newHtml},
            () => {
                notification['success']({ 
                    message: t`Dokument bol uložený`,
                    duration: 10, 
                });
            },
            this.loadDoc,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    }

    render() {
        const {html, isEdit} = this.state;
        
        return isEdit ? 
        <Editor
            value={html}
            onSave={this.saveDoc}
            onChange={this.onChange}
            fullHeight={true}
        />
        :
        <div className="p-3 full-size-width full-size-height overflow-y-scroll">
            <div 
                className="full-size-width"
                dangerouslySetInnerHTML={{__html: html}}
            />
            <RightsWrapper from={RIGHTS.MANAGER}>
                <div className="text-center">
                    <Button icon="edit" onClick={this.startEdit}>
                        <span className="pl-1"><Trans>Upraviť dokument</Trans></span>
                    </Button>
                </div>
            </RightsWrapper>
        </div>
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(CompanyDocument)
);