import React from 'react';
import Helmet from '../project/Helmet';
import {t} from '@lingui/macro';
import CompanyDocument from './CompanyDocument';
import { createFetchCompanyEditPrivacyPolicy, createFetchCompanyPrivacyPolicy } from '../../backend/apiCalls';

/**
 * @fero
 */

class PersonalDataProtectionPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Zásady ochrany osobných údajov`}
            />
            <CompanyDocument
                loadFcn={createFetchCompanyPrivacyPolicy()}
                saveFcn={createFetchCompanyEditPrivacyPolicy()}
            />
        </React.Fragment>;
    }

}

export default PersonalDataProtectionPage;